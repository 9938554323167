import 'react-universal-hooks'
import React from 'react'
import Layout from '../components/Layout'
import LoginPage from '../components/core/pages/LoginPage'
import Amplify from 'aws-amplify'
import apiConfig from '../utils/apiConfig'

Amplify.configure(apiConfig)

export default class Login extends React.Component {
  render = () => (
    <Layout {...this.props}>
      <LoginPage {...this.props} />
    </Layout>
  )
}
